@import "settings";
@import "normalize.css/normalize";

html {
  font-size: 16px !important;
}

body {
  font-family: "Inter", sans-serif;
}

#root {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.ReactModal__Overlay {
  background-color: rgba($color-black, 0.5) !important;
  overflow: auto;
  z-index: 100;
}

.ReactModal__Content {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0px 8px 40px rgba($color-black, 0.25);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.MuiButton-sizeXsmall {
  height: 40px;
}
